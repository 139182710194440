<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title: "Drone"
page.description : "แสดงและค้นหา Drone ที่มีอยู่ในระบบ"

drone.search.keyword : "ค้นหา"
drone.search.keyword.placeholder : "ค้นหาจากชื่อ Drone หรือ S/N"
button.create_drone : "เพิ่ม Drone ใหม่"
button.actions : "อื่น ๆ"
button.actions.export_register : "Download ข้อมูลลงทะเบียน"
button.actions.export_all : "Download Drones ทั้งหมด"

drone.lot.chart.name: "Lot"
drone.customer.chart.name: "เจ้าของเครื่อง"
drone.company.chart.name: "Drone ของแต่ละศูนย์บริการ"
drone.model.chart.name: "Drone"
drone.firmware.chart.name: "Firmware"
drone.status.chart.name: "สถานะเครื่อง"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')" />

		<div v-if="canCreateDrone || canRegister" class="page-action-right">
				<a-button
					v-if="canCreateDrone"
					class="btn-page-action"
					type="primary"
					icon="plus"
					@click="clickCreateDrone">
					{{$t('button.create_drone')}}
				</a-button>

				<a-dropdown v-if="canRegister">
					<a-menu slot="overlay" @click="handleDroneActions">
						<a-menu-item key="export_register"> <a-icon type="download" /> {{$t('button.actions.export_register')}}</a-menu-item>
						<a-menu-divider />
						<a-menu-item key="export_all"> <a-icon type="download" /> {{$t('button.actions.export_all')}}</a-menu-item>
					</a-menu>
					<a-button>{{$t('button.actions')}} <a-icon type="down" /></a-button>
				</a-dropdown>
		</div>
		<div class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item :label="$t('drone.search.keyword')">
					<a-input v-model="filter.keyword"
						style="width : 250px;"
						:placeholder="$t('drone.search.keyword.placeholder')"
						allow-clear >
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('drone.field.status')">
					<a-select v-model="filter.status_list"
						style="min-width:200px"
						mode="multiple"
						:placeholder="$t('common.search.view_all')"
						:options="statusOptions"
						allow-clear/>
				</a-form-item>
				<a-form-item class="myform-expandable-row">
					<ExpanableLink v-model="searchMore"
						class="search-more-link ant-form-text"
						:title="$t('common.search.more_options')"/>
				</a-form-item>

				<CollapseTransition>
					<div v-show="searchMore" class="myform-expandable-pane">
						<a-form-item :label="$t('drone.field.drone_model_id')">
							<a-select v-model="filter.drone_model_id"
								:loading="droneModelsLoading"
								style="min-width:200px"
								allow-clear
								:placeholder="$t('common.search.view_all')">
								<a-select-option v-for="model of droneModels" :key="model.id"
									:value="model.id">
									{{model.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item  :label="$t('drone.field.customer')">
							<CustomerSelect
								ref="customerPanel"
								v-model="filterCustomer"
								:placeholder="$t('common.search.view_all')"
								style="width : 250px"
								@change="onCustomerSelect"/>
						</a-form-item>
					</div>
				</CollapseTransition>
			</a-form>
		</div>
		<div class="myform-action-pane">
			<a-row>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart :name="$t('drone.company.chart.name')"
						:total-data="droneCompanyCount"
						:chart-data="statDroneCompanyData"
						@clicked="handleClickDroneCompanyChart"/>
				</a-col>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart :name="$t('drone.model.chart.name')"
						:total-data="droneModelCount"
						:chart-data="statDroneModelData"
						@clicked="handleClickDroneModelChart"/>
				</a-col>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart :name="$t('drone.firmware.chart.name')"
						:total-data="droneFirmwareCount"
						:chart-data="statDroneFirmwareData"
						@clicked="handleClickDroneFirmwareChart"/>
				</a-col>
				<a-col :md="6" class="chart-col">
					<DroneListPieChart :name="$t('drone.status.chart.name')"
						:total-data="droneStatusCount"
						:chart-data="statDroneStatusData"
						:is-enum-required="true"
						@clicked="handleClickDroneStatusChart"/>
				</a-col>
			</a-row>
			<a-row>
				<a-col :md="12" class="chart-col">
					<DroneListBarChart
						:name="$t('drone.lot.chart.name')"
						:chart-data="statDroneLotData"
						:total-data="droneLotCount"
						@clicked="handleClickDroneLotsChart"/>
				</a-col>
				<a-col :md="12" class="chart-col">
					<DroneListBarChart
						:name="$t('drone.customer.chart.name')"
						:chart-data="statDroneCustomerData"
						:total-data="droneCustomerCount"
						@clicked="handleClickDroneCustomerChart"/>
				</a-col>
			</a-row>
		</div>
		<a-card
			:bordered="false">
			<DroneTable
				bordered
				:show-pixhawk="true"
				:row-key="record => record.id"
				:data-source="droneDataList"
				:pagination="pagination"
				:loading="loading"
				@change="handleTableChange"/>
		</a-card>
	</div>
</template>

<script>
import {Row, Col} from 'ant-design-vue'
import axios from "axios"
import {CollapseTransition} from "vue2-transitions"
import _debounce from "lodash/debounce"
import fileDownload from "js-file-download"

import PageMixin from "@mixins/PageMixin.vue"
import ApiError from "@utils/errors/ApiError"
import { fulfillDrone } from '@utils/objectUtil'
import {getEnumSelectOptions} from "@utils/formUtil"
import CustomerSelect from "@components/customer/CustomerSelect.vue"
import DroneTable from "@components/drone/DroneTable.vue"
import ExpanableLink from "@components/common/ExpandableLink.vue"
import DroneListBarChart from "@components/chart/DroneListBarChart.vue"
import DroneListPieChart from '@components/chart/DroneListPieChart.vue'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
    ExpanableLink,
    CollapseTransition,
    DroneTable,
    CustomerSelect,
    DroneListBarChart,
    "a-row": Row,
    "a-col": Col,
    DroneListPieChart
} ,
	mixins : [PageMixin] ,
	data() {
		return {
			searchMore : false,
			droneDataList : [] ,
			filterCustomer : {} ,
			pagination : {
				pageSize : 20 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : 'serial_no',
				order : 'ascend',
			} ,
			filter : {
				keyword : "",
				customer_id : undefined,
				status_list : [],
				drone_model_id : undefined,
				drone_lot_id : undefined,
				drone_lot_name: undefined,
				drone_firmware: undefined,
				drone_company: undefined,
			} ,
			loading : false,
			droneModels : [] ,
			droneModelsLoading : false ,

			statDroneLotData: {},
			droneLotCount: 0,
			statDroneCustomerData: {},
			droneCustomerCount: 0,
			statDroneModelData: {},
			droneModelCount: 0,
			statDroneFirmwareData: {},
			droneFirmwareCount: 0,
			statDroneStatusData: {},
			droneStatusCount: 0,
			statDroneCompanyData: {},
			droneCompanyCount: 0,
		}
	} ,
	computed : {
		canCreateDrone() {
			return this.$authorize('create','drone')
		} ,
		canRegister() {
			return this.$authorize('register','drone')
		} ,

		statusOptions() {
			const options = ['new','active','backup','decommission']
			return getEnumSelectOptions(this,'drone.status',options)
		}
	} ,
	watch : {
		filter : {
			handler() {
				this.debounceSearchChange()
			} ,
			deep : true
		}
	},
	created() {
		this.debounceSearchChange = _debounce(this.handleSearchChange,300);
		this.fetchModels()
	} ,
	mounted() {
		this.fetchData();
		this.fetchDroneLotsStat()
		this.fetchDroneCustomerStat()
		this.fetchDroneModelStat()
		this.fetchDroneFirmwareStat()
		this.fetchDroneStatusStat()
		this.fetchDroneCompanyStat()
	} ,
	methods : {
		fetchModels() {
			this.droneModelsLoading = true
			axios.get("/api/drone-models/active").then((response) => {
				this.droneModels = response.data.data.drone_models
			}).catch((error) => {
				this.fetchError(error)
			}).finally(()=>{
				this.droneModelsLoading = false
			})
		} ,
		handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1;
			this.fetchData();
		} ,
		fetchData(toPage=null) {
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : toPage,
				sort_field : this.sort.field ,
				sort_order : this.sort.order ,
			}
			this.loading = true;
			axios.get("/api/drones/search",{params : {search,filter : this.filter}}).then((response)=>{
				const page = response.data.data.pagination;
				const dataBucket = response.data.data.bucket
				this.droneDataList = response.data.data.drones.map((drone)=>{
					return fulfillDrone(drone, dataBucket)
				})

				this.pagination.current = toPage
				this.pagination.total = page.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.order === undefined ? null : sorter.columnKey
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		} ,
		clickCreateDrone() {
			this.$open({name:"admin/drone_create"})
		} ,
		onCustomerSelect(customer) {
			if(customer.id === undefined)
				this.statDroneCustomerData = {...this.statDroneCustomerData}
			this.filterCustomer = {...customer}
			this.filter.customer_id = customer.id ? customer.id : undefined
		} ,
		handleDroneActions(event) {
			if (!this.canRegister)
				return
			const menu = event.key
		 	if (menu == 'export_register') {
				this.showPageLoading(true)
				axios.get("/api/drones/export-registered",{
					responseType: 'blob'
				}).then((response) => {
					fileDownload(response.data,"registered_drones.xlsx");
				}).catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this,error));
				}).finally(() => {
					this.hidePageLoading()
				})
			} else if (menu == 'export_all') {
				this.showPageLoading(true)
				axios.get("/api/drones/export",{
					responseType: 'blob'
				}).then((response) => {
					fileDownload(response.data,"drones.xlsx");
				}).catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this,error));
				}).finally(() => {
					this.hidePageLoading()
				})
			}
		},
		fetchDroneLotsStat(){
			axios.get("/api/drones/stat-drones-per-lot").then((response) => {
				const data = response.data.data
				const datasets = data.dataset
				datasets.sort((a,b)=>b.num_drones - a.num_drones)
				const newDataset = {
					labels: data.dataset.map(e=>e.lot_name),
					datasets: [{
						label: 'number of drone',
						backgroundColor: '#ff6d22',
						data: data.dataset.map(e=>e.num_drones),
					}]
				}
				this.statDroneLotData = newDataset
				this.droneLotCount = data.total
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			})
		},
		fetchDroneCustomerStat(){
			axios.get("/api/drones/stat-drones-per-customer").then((response) => {
				const data = response.data.data
				const datasets = data.dataset
				datasets.sort((a,b)=>b.num_drones - a.num_drones)
				const newDataset = {
					labels: data.dataset.map(e=>e.name),
					datasets: [{
						label: 'number of drone',
						backgroundColor: '#f7e013',
						data: data.dataset.map(e=>e.num_drones),
					}]
				}
				this.statDroneCustomerData = newDataset
				this.droneCustomerCount = data.total
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			})
		},
		fetchDroneModelStat(){
			axios.get("/api/drones/stat-drones-per-model").then((response) => {
				const data = response.data.data
				const datasets = data.dataset
				datasets.sort((a,b)=>b.num_drones - a.num_drones)
				const newDataset = {}
				for (const e of data.dataset){
					newDataset[e.display_name] = e.num_drones
				}
				this.statDroneModelData = newDataset
				this.droneModelCount = data.total
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			})
		},
		fetchDroneFirmwareStat(){
			axios.get("/api/drones/stat-drones-per-firmware").then((response) => {
				const data = response.data.data
				const datasets = data.dataset
				datasets.sort((a,b)=>b.num_drones - a.num_drones);
				const newDataset = {};
				for (const e of data.dataset){
					newDataset[e.name] = e.num_drones
				}
				this.statDroneFirmwareData = newDataset;
				this.droneFirmwareCount = data.total
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			})
		},
		fetchDroneStatusStat(){
			axios.get("/api/drones/stat-drones-per-status").then((response) => {
				const data = response.data.data
				const datasets = data.dataset
				datasets.sort((a,b)=>b.num_drones - a.num_drones)
				const newDataset = {}
				for (const e of data.dataset){
					newDataset[e.drone_status] = e.num_drones
				}
				this.statDroneStatusData = newDataset
				this.droneStatusCount = data.total
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			})
		},
		fetchDroneCompanyStat(){
			axios.get("/api/drones/stat-drones-per-company").then((response) => {
				const data = response.data.data
				const datasets = data.dataset
				datasets.sort((a,b)=>b.num_drones - a.num_drones)
				const newDataset = {}
				for (const e of data.dataset){
					newDataset[e.company_name] = e.num_drones
				}
				this.statDroneCompanyData = newDataset
				this.droneCompanyCount = data.total
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			})
		},
		handleClickDroneLotsChart(selectedValue) {
			this.filter.drone_lot_name = selectedValue
		},
		handleClickDroneCustomerChart(selectedValue) {
			if(selectedValue === undefined){
				this.$refs.customerPanel.clearSearchCustomer()
				this.$refs.customerPanel.handleChange(undefined)
				this.$refs.customerPanel.searchCustomer({search:'',page:1})
			}
			else{
				this.$refs.customerPanel.searchCustomer({search:selectedValue}, true)
			}
		},
		handleClickDroneModelChart(selectedValue) {
			const filteredModel = this.droneModels.filter(drone=>{
				return drone.display_name === selectedValue
			})
			if (filteredModel.length > 0) {
				this.filter.drone_model_id = filteredModel[0].id
			}
			else {
				this.filter.drone_model_id = undefined
			}
		},
		handleClickDroneFirmwareChart(selectedValue) {
			this.filter.drone_firmware = selectedValue
		},
		handleClickDroneStatusChart(selectedValue) {
			const statusList = ['new','active','backup','decommission']
			if (selectedValue === undefined) {
				this.filter.status_list = []
			} else {
				const selectedOption = statusList.find(items => (this.$tenum('drone.status', items)) === selectedValue)
				this.filter.status_list = [selectedOption]
			}
		},
		handleClickDroneCompanyChart(selectedValue) {
			this.filter.drone_company = selectedValue
		},
	}
}
</script>

<style lang="less" scoped>
.chart-col{
	div{
		margin: auto
	}
}
</style>
